<template>
  <v-row class="d-flex justify-center mt-0">
    <!-- Datos de la Liquidación -->
    <v-col cols="12" class="pt-0">
      <v-expansion-panels v-model="panel0">
        <v-expansion-panel readonly>
          <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
            <div>
              Datos de la Liquidación
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel0 = panel0 === 1? 0 : 1">
                <v-icon> fas fa-chevron-down </v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-1">
            <v-form ref="formDatosLiq">
              <v-row class="pt-2">
                <v-col cols="12" sm="6" md="3" class="py-1">
                  Liquidación Id
                  <v-text-field
                    v-model.trim="datosLiquidacion.id"
                    :readonly="true"
                    :filled="true"
                    hide-details
                    outlined
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-1">
                  Nro. Resumen
                  <v-text-field
                    v-model.trim="datosLiquidacion.nro_resumen"
                    :readonly="true"
                    :filled="true"
                    hide-details
                    outlined
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-1">
                  Nro. Liquidación
                  <v-text-field
                    v-model.trim="datosLiquidacion.nro_liquidacion"
                    :readonly="true"
                    :filled="true"
                    hide-details
                    outlined
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-1">
                  Nro. Comercio
                  <v-text-field
                    v-model.trim="datosLiquidacion.nro_comercio"
                    :readonly="true"
                    :filled="true"
                    hide-details
                    outlined
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-1">
                  Forma Cobro
                  <v-text-field
                    v-model.trim="datosLiquidacion.forma_cobro_nombre"
                    :readonly="true"
                    :filled="true"
                    hide-details
                    outlined
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-1">
                  Tarjeta
                  <v-text-field
                    v-model.trim="datosLiquidacion.tarjeta_nombre"
                    :readonly="true"
                    :filled="true"
                    hide-details
                    outlined
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-1">
                  Pago Tarjeta
                  <v-text-field
                    v-model.trim="datosLiquidacion.forma_pago_tar_nombre"
                    :readonly="true"
                    :filled="true"
                    hide-details
                    outlined
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-1">
                  Mov. Bancario
                  <v-text-field
                    v-model.trim="datosLiquidacion.con_mov_bancario"
                    readonly
                    filled
                    hide-details
                    outlined
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6" md="3" class="py-1">
                  Fecha Grabación
                  <v-text-field
                    v-model.trim="datosLiquidacion.fecha_grabacion"
                    :readonly="true"
                    :filled="true"
                    hide-details
                    outlined
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6" sm="" md="3" class="py-1">
                  Usuario Grabación
                  <v-text-field
                    v-model.trim="datosLiquidacion.usuario"
                    :readonly="true"
                    :filled="true"
                    hide-details
                    outlined
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6" sm="" md="3" class="py-1">
                  Fecha Pago
                  <FechaPickerVue
                    v-model="datosLiquidacion.fecha_pago"
                    :clearable="true"
                    :hideDetails="true"
                  />
                </v-col>
                <v-col  cols="6" sm="" md="3" class="py-1">
                  Fecha Emisión
                  <FechaPickerVue
                    v-model="datosLiquidacion.fecha_emision"
                    :clearable="true"
                    :hideDetails="true"
                  />
                </v-col>
                <v-col  cols="12" sm="" md="12" class="py-1">
                  Nombre Archivo
                  <v-text-field
                    v-model.trim="datosLiquidacion.archivo_nombre"
                    :readonly="true"
                    :filled="true"
                    hide-details
                    outlined
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6" md="3" class="py-1">
                  Total Bruto
                  <TextFieldMoneyVue
                    v-model="datosLiquidacion.total_bruto"
                    outlined
                    dense
                    v-bind:properties="{
                      prefix: '$',
                      'hide-details': true,
                      'validate-on-blur': true,
                      'readonly': true,
                      'filled': true
                    }"
                  />
                </v-col>
                <v-col cols="6" md="2" class="py-1">
                  Total Diferencia
                  <TextFieldMoneyVue
                    v-model="datosLiquidacion.total_diferencia"
                    outlined
                    dense
                    v-bind:properties="{
                      prefix: '$',
                      'hide-details': true,
                      'validate-on-blur': true,
                      'readonly': true,
                      'filled': true
                    }"
                  />
                </v-col>
                <v-col cols="6" md="3" class="py-1">
                  Total
                  <TextFieldMoneyVue
                    v-model="datosLiquidacion.total_bb"
                    outlined
                    dense
                    v-bind:properties="{
                      prefix: '$',
                      'hide-details': true,
                      'validate-on-blur': true,
                      'readonly': true,
                      'filled': true
                    }"
                  />
                </v-col>
                <v-col cols="6" md="2" class="py-1">
                  Total Descuento
                  <TextFieldMoneyVue
                    v-model="datosLiquidacion.descuentos"
                    outlined
                    dense
                    v-bind:properties="{
                      prefix: '$',
                      'hide-details': true,
                      'validate-on-blur': true,
                      'readonly': true,
                      'filled': true
                    }"
                  />
                </v-col>
                <v-col cols="6" md="2" class="py-1">
                  Total Neto
                  <TextFieldMoneyVue
                    v-model="datosLiquidacion.total_neto"
                    outlined
                    dense
                    v-bind:properties="{
                      prefix: '$',
                      'hide-details': true,
                      'validate-on-blur': true,
                      'readonly': true,
                      'filled': true
                    }"
                  />
                </v-col>
              </v-row>
              <v-row >
                <v-col cols="12" class="d-flex pa-3" :class="pantallaChica ? 'flex-column' : 'justify-end'" >
                  <BtnConfirmarVue
                    @action="guardar()"
                    color="success"
                  />
                  <v-btn color="info":class="pantallaChica ? 'mt-2 mb-2' : 'ml-2 mr-2' " @click="conciliarLiquidación()">Conciliar Liquidación</v-btn>
                  <v-btn color="warning" class="" @click="generarMovBancario()">Generar Mov. Bancario</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>

    <!-- Componente GenMovBancParaLiq -->
    <ModalMovBancLiqVue
      v-model="dialogMov"
      :datos="movBancLiqObj"
    />

    <!-- Detalles de liquidación -->
    <v-col cols="12" class="pt-0 pb-0" id="SeccionDetalles">
      <v-expansion-panels v-model="panel1" class="">
        <v-expansion-panel readonly>
          <v-expansion-panel-header class="pt-2 py-0 px-4 text-h5" style="cursor: default">
            <div>
              Detalles de la Liquidación
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel1 = panel1 === 1? 0 : 1">
                <v-icon> fas fa-chevron-down </v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-4 pb-4">
            <v-row>
              <v-col cols="12" class="pa-0">
                <v-data-table
                  sort-by="abreviacion"
                  class="cebra elevation-2 mt-0"
                  :headers="headers"
                  :items="detallesLiq"
                  :loading="load2"
                  :search="search"
                  :footer-props="{'items-per-page-options':[5, 10, 15, 50, 100]}"
                  dense
                  item-key="id"
                  single-expand
                  single-select
                  :expanded.sync="expanded"
                  @click:row="rowClick"
                >
                  <template v-slot:top>
                    <v-row class="d-flex justify-end pa-3" no-gutters>
                      <v-col cols="6" sm="3" md="2">
                        <SearchDataTableVue
                          v-model="search"
                        />
                      </v-col>
                    </v-row>
                  </template>
                  <template v-slot:no-data>
                    <v-alert
                      class="mx-auto mt-4"
                      max-width="400"
                      type="warning"
                      border="left"
                      dense
                      text
                    >
                      No hay datos para los filtros seleccionados
                    </v-alert>
                  </template>
                  <template
                    v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))"
                    v-slot:[`item.${header.value}`]="{ value }"
                  >
                    {{ header.formatter(value) }}
                  </template>
                  <template v-slot:[`item.acciones`]="{ item }">
                    <v-icon
                      v-if="item.estado_id == 5 || item.estado_id == 2"
                      color="error"
                      title="Desconciliar"
                      small
                      @click.stop="desconciliarUna(item)"
                    >
                      far fa-times-circle
                    </v-icon>
                    <v-icon
                      v-if="item.estado_id == 5 || item.estado_id == 1"
                      color="success"
                      title="Conciliar OK"
                      small
                      @click.stop="conciliarOk(item)"
                      class="ml-1 mr-1"
                    >
                      far fa-check-circle
                    </v-icon>
                    <v-icon
                      v-if="item.estado_id == 1"
                      color="info"
                      title="Conciliar con Observación"
                      small
                      @click.stop="conciliarConObs(item)"
                    >
                      far fa-check-circle
                    </v-icon>
                  </template>
                  <template v-slot:[`item.conciliacion_manual`]="{ item }">
                    <v-checkbox
                      v-model="item.conciliacion_manual"
                      class="pb-1"
                      :true-value="1"
                      :false-value="0"
                      readonly
                      disabled
                      hide-details
                      dense
                    ></v-checkbox>
                  </template>
                  <template v-slot:[`item.estado_nombre`]={item}>
                    <v-chip :color="item.estado_id == 1 ? 'error' : (item.estado_id == 2 ? 'success' : 'orange')" small >{{ item.estado_nombre }}</v-chip>
                  </template>
                  <!-- Contenido de la Fila expandida -->
                  <template v-slot:expanded-item="{ headers, item }" >
                    <td :colspan="headers.length" class="">
                      <div style="width:100%;">
                        <v-data-table
                          class="cebra my-2"
                          :headers="headers_det"
                          :items="datosPedido"
                          :loading="load2_1"
                          hide-default-footer
                          :items-per-page="-1"
                          dense
                        >
                          <template v-slot:no-data>
                            <v-alert
                              class="mx-auto mt-4"
                              max-width="400"
                              type="warning"
                              border="left"
                              dense
                              text
                            >
                              Sin un pedido conciliado
                            </v-alert>
                          </template>
                          <template
                            v-for="header in headers_det.filter((header) => header.hasOwnProperty('formatter'))"
                            v-slot:[`item.${header.value}`]="{ value }"
                          >
                            {{ header.formatter(value) }}
                          </template>
                          <template v-slot:[`item.acciones`]="{ item }">
                            <v-icon
                              color="warning"
                              title="Ver"
                              small
                              @click="verDatosCaja(item)"
                            >
                              fas fa-eye
                            </v-icon>
                          </template>
                          <template slot="body.append">
                            <td :colspan="headers.length" class="pl-2 pe-2">
                              <v-text-field
                                outlined
                                dense
                                label="Observación"
                                v-model="item.observacion"
                                hide-details
                                type="text"
                                class="mt-4 mb-2">
                                <template v-slot:append>
                                  <v-btn icon small>
                                    <v-icon
                                      color="success"
                                      title="Guardar observación"
                                      small
                                      @click="guardarObs(item)"
                                    >
                                      fas fa-save
                                    </v-icon>
                                  </v-btn>
                                </template>
                              </v-text-field>
                            </td>
                          </template>
                        </v-data-table>
                      </div>
                    </td>
                  </template>
                  <!-- ultima row para el total -->
                  <template slot="body.append">
                    <tr class="font-weight-bold">
                      <th style="font-size: 1em" colspan="6"> Totales</th>
                      <th style="font-size: 1em">{{ formatMoney(retornaAcuImpLiq) }}</th>
                      <th style="font-size: 1em">{{ formatMoney(retornaAcuDif) }}</th>
                      <th style="font-size: 1em">{{ formatMoney(retornaImporteAcu) }}</th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </template>
                </v-data-table> 
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>

    <!-- Pedidos recomendados -->
    <v-col cols="12" class="pb-0">
      <v-expansion-panels v-model="panel2_0" class="">
        <v-expansion-panel readonly>
          <v-expansion-panel-header class="pt-4 pb-4 py-0 px-4 text-h5" style="cursor: default">
            <div >
              Pedidos recomendados para: 
              <span v-if="panel2_0 == 0">Tarjeta Número: {{ nroTarjeta }}, Lote: {{nroLote}}, Cupón: {{nroCupon}}</span>
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel2_0 = panel2_0 === 1? 0 : 1">
                <v-icon> fas fa-chevron-down </v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-1">
            <v-form ref="formPedidosEstimados" @submit.prevent="buscar()" class="mb-4">
              <v-row>
                <v-col cols="6" md="3" class="py-1">
                  Núm. Pedido
                  <v-text-field
                    v-model.trim="filtrosPedidosEstimados.numPedido"
                    hide-details
                    outlined
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6" md="3" class="py-1">
                  Tipo Pedido
                  <v-autocomplete
                    v-model="filtrosPedidosEstimados.tipoPedido"
                    item-text="tipo_pedido_nombre"
                    item-value="tipo_pedido_id"
                    tabindex="1"
                    :items="tiposPedidos"
                    hide-details
                    outlined
                    dense clearable
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" md="3"  class="py-1">
                  Forma de Pago
                  <v-autocomplete
                    v-model="filtrosPedidosEstimados.formaPago"
                    item-text="forma_pago_nombre"
                    item-value="forma_pago_id"
                    tabindex="1"
                    :items="formasPago"
                    hide-details
                    outlined
                    dense clearable
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" md="3"  class="py-1">
                  Cód. Autorización
                  <v-text-field
                    v-model.trim="filtrosPedidosEstimados.codAutor"
                    hide-details
                    outlined
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6" md="3" class="py-1">
                  Nro. Tarj.
                  <v-text-field
                    v-model.trim="filtrosPedidosEstimados.nroTar"
                    hide-details
                    outlined
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6" md="3" class="py-1">
                  Marca
                  <v-autocomplete
                    v-model="filtrosPedidosEstimados.marcaNro"
                    item-text="tarjeta_nombre"
                    item-value="tarjeta_codigo"
                    tabindex="1"
                    :items="tarjetas"
                    hide-details
                    outlined
                    dense clearable
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" md="3" class="py-1">
                  Lote
                  <v-text-field
                    v-model.trim="filtrosPedidosEstimados.lote"
                    hide-details
                    outlined
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6" md="3" class="py-1">
                  Cupón
                  <v-text-field
                    v-model.trim="filtrosPedidosEstimados.cupon"
                    hide-details
                    outlined
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6" md="3" class="py-1">
                  Cja. Nro.
                  <v-text-field
                    v-model.trim="filtrosPedidosEstimados.cjaNro"
                    hide-details
                    outlined
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6" md="3" class="py-1">
                  Fecha Desde
                  <FechaPickerVue
                    v-model="filtrosPedidosEstimados.fechaDesde"
                    :clearable="true"
                    :hideDetails="true"
                  />
                </v-col>
                <v-col cols="6" md="3"  class="py-1">
                  Fecha hasta
                  <FechaPickerVue
                    v-model="filtrosPedidosEstimados.fechaHasta"
                    :clearable="true"
                    :hideDetails="true"
                  />
                </v-col>
                <v-col cols="6" md="3" class="py-1">
                  Importe
                  <TextFieldMoneyVue
                    v-model="filtrosPedidosEstimados.importe"
                    outlined
                    dense
                    v-bind:properties="{
                      prefix: '$',
                      'hide-details': true,
                      'validate-on-blur': true,
                    }"
                  />
                </v-col>
                <v-col cols="12" class="d-flex justify-end py-1">
                  <BtnFiltroVue
                    :disabled="!seleccion"
                    :loading="load"
                    clase="mt-0"
                    @clear="limpiar()"
                  />
                </v-col>
              </v-row>
            </v-form>
            <v-divider></v-divider>
            <v-row class="pa-0" id="pedidosRecomendados">
              <v-col cols="12">
                <v-data-table
                  class="cebra elevation-2 mt-6"
                  :headers="headers_2"
                  :items="estimados"
                  :loading="load"
                  :search="search_2"
                  :footer-props="{'items-per-page-options':[5, 10, 15, 50, 100]}"
                  dense
                >
                  <template v-slot:top>
                    <v-row class="d-flex justify-end pa-3" no-gutters>
                      <v-col cols="6" sm="3" md="2">
                        <SearchDataTableVue
                          v-model="search_2"
                        />
                      </v-col>
                    </v-row>
                  </template>
                  <template v-slot:no-data>
                    <v-alert
                      class="mx-auto mt-4"
                      max-width="400"
                      type="warning"
                      border="left"
                      dense
                      text
                    >
                      No hay datos para los filtros seleccionados
                    </v-alert>
                  </template>
                  <template
                    v-for="header in headers_2.filter((header) => header.hasOwnProperty('formatter'))"
                    v-slot:[`item.${header.value}`]="{ value }"
                  >
                    {{ header.formatter(value) }}
                  </template>
                  <template v-slot:[`item.acciones`]="{ item }">
                    <v-icon
                      color="info"
                      title="Ver Datos de Caja"
                      small
                      @click="open_DatosdeCaja(item)"
                    >
                      fas fa-eye
                    </v-icon>
                    <v-icon 
                      color="success" 
                      title="Conciliar Manual"
                      small class="ml-2"
                      @click="conciliarManualOp(item)"
                    >
                      fas fa-check-circle
                    </v-icon>
                  </template>
                </v-data-table> 
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
    <!-- Datos del Pedido -->
    <ModalDatosPedidoVue
      v-model="dialogDP"
      :datos="itemObj"
    />

    <!-- Descuentos -->
    <v-col cols="12" class="pb-0">
      <v-expansion-panels v-model="panel3" class="">
        <v-expansion-panel readonly>
          <v-expansion-panel-header class="pt-4 pb-4 py-0 px-4 text-h5" style="cursor: default">
            <div>
              Descuentos
              <v-btn v-if="panel3 == 0"
                icon small class="pa-2" 
                title="Agregar nuevo" color="success"
                @click="open_modalAgregarDescuentos()"
              > <v-icon>fas fa-plus-circle</v-icon> </v-btn>
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel3 = panel3 === 1? 0 : 1">
                <v-icon> fas fa-chevron-down </v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-1">
            <v-form class="mb-8">
              <v-row>
                <v-col cols="6" md="3" class="py-1">
                  Total Bruto
                  <TextFieldMoneyVue
                    v-model="datosLiquidacion.total_bruto"
                    outlined
                    dense
                    v-bind:properties="{
                      prefix: '$',
                      'hide-details': true,
                      'validate-on-blur': true,
                      'readonly': true,
                      'filled': true
                    }"
                  />
                </v-col>
                <v-col cols="6" md="2" class="py-1">
                  Total Diferencia
                  <TextFieldMoneyVue
                    v-model="datosLiquidacion.total_diferencia"
                    outlined
                    dense
                    v-bind:properties="{
                      prefix: '$',
                      'hide-details': true,
                      'validate-on-blur': true,
                      'readonly': true,
                      'filled': true
                    }"
                  />
                </v-col>
                <v-col cols="6" md="3" class="py-1">
                  Total
                  <TextFieldMoneyVue
                    v-model="datosLiquidacion.total_bb"
                    outlined
                    dense
                    v-bind:properties="{
                      prefix: '$',
                      'hide-details': true,
                      'validate-on-blur': true,
                      'readonly': true,
                      'filled': true
                    }"
                  />
                </v-col>
                <v-col cols="6" md="2" class="py-1">
                  Total Descuento
                  <TextFieldMoneyVue
                    v-model="datosLiquidacion.descuentos"
                    outlined
                    dense
                    v-bind:properties="{
                      prefix: '$',
                      'hide-details': true,
                      'validate-on-blur': true,
                      'readonly': true,
                      'filled': true
                    }"
                  />
                </v-col>
                <v-col cols="6" md="2" class="py-1">
                  Total Neto
                  <TextFieldMoneyVue
                    v-model="datosLiquidacion.total_neto"
                    outlined
                    dense
                    v-bind:properties="{
                      prefix: '$',
                      'hide-details': true,
                      'validate-on-blur': true,
                      'readonly': true,
                      'filled': true
                    }"
                  />
                </v-col>
              </v-row>
            </v-form>
            <v-divider></v-divider>
            <v-row class="pa-0">
              <v-col cols="12" class="pt-0 pb-0">
                <v-data-table
                  class="cebra elevation-2 mt-6"
                  :headers="headers_3"
                  sort-by="descuento_id"
                  :items="descuentosLiq"
                  :loading="load3"
                  :search="search_3"
                  dense
                >
                  <template v-slot:top>
                    <v-row class="d-flex justify-end pa-3" no-gutters>
                      <v-col cols="6" sm="3" md="2">
                        <SearchDataTableVue
                          v-model="search_3"
                        />
                      </v-col>
                    </v-row>
                  </template>
                  <template v-slot:no-data>
                    <v-alert
                      class="mx-auto mt-4"
                      max-width="400"
                      type="warning"
                      border="left"
                      dense
                      text
                    >
                      No hay datos para los filtros seleccionados
                    </v-alert>
                  </template>
                  <template
                    v-for="header in headers_3.filter((header) => header.hasOwnProperty('formatter'))"
                    v-slot:[`item.${header.value}`]="{ value }"
                  >
                    {{ header.formatter(value) }}
                  </template>
                  <template v-slot:[`item.acciones`]="{ item }">
                    <v-icon
                      color="error"
                      title="Eliminar descuento"
                      small
                      @click="elim_descuento(item)"
                    >
                      fas fa-times-circle
                    </v-icon>
                    <v-icon 
                      color="indigo" title="Editar descuento"
                      small class="pl-2" @click="editar_descuento(item)"
                    >
                      fas fa-pen
                    </v-icon>
                  </template>
                  <!-- ultima row para el total -->
                  <template slot="body.append">
                    <tr class="font-weight-bold">
                      <th style="font-size: 1em" colspan="3"> Totales Descuentos </th>
                      <th style="font-size: 1em">{{ formatMoney(retornaAcuDesc) }}</th>
                      <th></th>
                    </tr>
                  </template>
                </v-data-table> 
              </v-col>
            </v-row>
            <v-row class="d-flex justify-end pa-3">
              <BtnConfirmarVue
                @action="guardarDescuentos()"
                color="success"
              />
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
    <!-- Componentes -->
    <ModalAgregarDescuentoVue
     v-model="dialogDesc"
     :datos="descuentosObj"
     @agregarNvo="agregarNvoDescuento"
     @editar_desc="editarDescuento"
    />
    
  </v-row>
</template>

<script>
import ModalAgregarDescuentoVue from '../../components/auditoria/ModalAgregarDescuento.vue';
import ModalMovBancLiqVue from '../../components/auditoria/ModalMovBancLiq.vue';
import BtnConfirmarVue from '../../components/util/BtnConfirmar.vue';
import BtnFiltroVue from '../../components/util/BtnFiltro.vue';
import FechaPickerVue from '../../components/util/FechaPicker.vue';
import SearchDataTableVue from '../../components/util/SearchDataTable.vue';
import TextFieldMoneyVue from '../../components/util/TextFieldMoney.vue';
import { format_money, objetoNoVacio, cadenaVacia, roundNumber } from '../../util/utils'
import moment from 'moment'
import ModalDatosPedidoVue from '../../components/auditoria/ModalDatosPedido.vue';


export default {
  name: 'UnaConciliacion',
  data(){
    return{
      moment: moment,
      load: false,
      load2: false,
      load2_1:false,
      load3: false,
      editarDesc: false,
      search: '',
      search_2: '',
      search_3: '',
      formatMoney: format_money,
      //para panels expandibles
      panel0: 0,
      panel1: 0,
      panel2_0: 0,
      panel2_1: 0,
      panel3: 0,
      //para Modales
      dialogMov: false,
      movBancLiqObj: {},
      dialogDesc: false,
      descuentosObj: {},
      dialogDescCompleto: false,
      descCompleto: {},
      //initConciliacion
      formasCobros:[], //si ver = 0
      tarjetas:[],
      formasPagoTarjeta: [],
      tiposPedidos:[], //si ver = 1
      formasPago:[],
      descuentos:[],
      //Filtros del panel general
      filtros:{
        formasDeCobro: null,
        tarjeta: null,
        formaPagoTarjeta: null,
        liqId: this.$route.params.id,//null,
        nroResumen: null,
        nroComercio: null,
        fechaDesde: null,
        fechaHasta: null,
        movBancario: null
      },
      //searchLiquidaciones
      datosLiquidacion: {},
      detallesLiq: [],
      descuentosLiq: [],
      //panel detalles
      expanded:[],
      //conciliarManualBool: false, //checkbox
      //conciliarManualValor: null, //checkbox
      //getDatosDetalleLiq
      datosPedido:[],
      datosCaja:[],
      estimaciones:[],
      //DataTable Detalles
      headers:[
        { text: 'Fecha Op.', value: 'fecha_operacion' },
        { text: 'Estado', value: 'estado_nombre' },
        { text: 'Lote', value: 'lote' },
        { text: 'Cupón', value: 'cupon' },
        { text: 'Tar. Nro.', value: 'tarjeta_numero' },
        { text: 'Cod. Autor.', value: 'codigo_autoriz' },
        { text: 'Imp. Liq.', value: 'importe_liq', formatter: format_money, align: 'end' },
        { text: 'Dif', value: 'diferencia', formatter: format_money, align: 'end' },
        { text: 'Importe', value: 'importe_bb', formatter: format_money, align: 'end'},
        { text: 'Conc. Mnal.', value: 'conciliacion_manual',align:'center' },
        { text: 'Cuotas', value: 'cuotas' },
        { text: 'Acciones', value: 'acciones', align: 'center', sorteable: false, filtreable: false },
      ],
      headers_det:[
        { text: 'Tipo Pedido', value: 'tipo_pedido_nombre' },
        { text: 'Fecha', value: 'fecha' },
        { text: 'Lote', value: 'lote' },
        { text: 'Cupón', value: 'cupon' },
        { text: 'Tar. Num.', value: 'tarjeta_numero' },
        { text: 'Autoriz.', value: 'codigo_autorizacion' },
        { text: 'Importe', value: 'importe', formatter: format_money, align: 'end' },
        { text: 'Num. Pedido', value: 'numero_pedido' },
        { text: 'Marca', value: 'marca'},
        { text: 'Forma Pago', value: 'forma_pago_nombre' },
        { text: 'Cuotas', value: 'cuotas' },
        { text: 'Acción', value: 'acciones',  align: 'center',sorteable: false, filtreable: false },
      ],
      //panel pedidos
      nroTarjeta: null,
      nroLote: null,
      nroCupon: null,
      ultimaDetLiq: null,
      seleccion: false,
      conManual: false, //sirve como bandera para marcar las conciliaciones manuales
      itemObj:{
        item: {},
        datos_caja: []
      },
      dialogDP: false,
      //FiltroPanelPedidosestimados
      filtrosPedidosEstimados:{
        numPedido: null,
        tipoPedido: null,
        formaPago: null,
        codAutor: null,
        nroTar: null,
        marcaNro: null,
        marcaNom: null,
        lote: null,
        cupon: null,
        cjaNro: null,
        fechaDesde: null,
        fechaHasta: null,
        importe: null,
        id: cadenaVacia(this.$route.params.id) ? null : this.$route.params.id
      },
      //getPedidosEstimados
      estimados:[],
      //DataTable Estimados
      headers_2:[
        { text: 'Tipo Pedido', value: 'tipo_pedido_nombre' },
        { text: 'Fecha', value: 'fecha' },
        { text: 'Lote', value: 'lote' },
        { text: 'Cupon', value: 'cupon' },
        { text: 'Tar. Num.', value: 'tarjeta_numero' },
        { text: 'Autoriz.', value: 'codigo_autorizacion' },
        { text: 'Importe', value: 'importe', formatter: format_money, align: 'end' },
        { text: 'Num. Pedido', value: 'numero_pedido' },
        { text: 'Marca', value: 'marca' },
        { text: 'Forma Pago', value: 'forma_pago_nombre' },
        { text: 'Cuotas', value: 'cuotas' },
        { text: 'Acciones', value: 'acciones', sorteable: false, filtreable: false },
      ],
      //DataTable Descuentos
      headers_3: [
        { text: 'Código', value: 'descuento_id' },
        { text: 'Tipo Descuento', value: 'tipo_descuento_nombre' },
        { text: 'Nombre descuento', value: 'descuento_nombre' },
        { text: 'Importe', value: 'importe', formatter: format_money },
        { text: 'Acciones', value: 'acciones', sorteable: false, filtreable: false }
      ],
      //breakpoints
      pantallaChica: this.$vuetify.breakpoint.xs,
    }
  },
  created(){
    this.iniciaLiq();
  },
  methods:{
    async iniciaLiq(){
      this.$store.state.loading = true;
      await this.$store.dispatch('conciliacionManual/initConciliacionManual',1)
        .then((res) => {
          this.formasCobros = res.formas_cobro;
          this.tarjetas = res.tarjetas;
          this.formasPagoTarjeta = res.formas_pago_tarjeta;
          this.tiposPedidos = res.tipos_pedidos;
          this.formasPago = res.formas_pago;
          this.descuentos = res.descuentos;
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      let acu = 0;
      await this.$store.dispatch('conciliacionManual/searchLiquidaciones',{
        filtro: this.filtros,
        ver: 1 
      })
        .then((res) => {
          this.datosLiquidacion = res.liquidaciones[0];
          this.datosLiquidacion.total_diferencia = 0; //agrega el campo en 0 
          this.detallesLiq = res.detalles;
          this.descuentosLiq = res.descuentos;
          this.descuentosLiq.forEach(elem => acu += elem.importe) //acumula los importes del descuento y lo muestra en el panel 1
          this.datosLiquidacion.descuentos = parseFloat(acu.toFixed(2))
          this.datosLiquidacion.total_neto = this.datosLiquidacion.total_bruto - this.datosLiquidacion.descuentos;
          this.datosLiquidacion.total_neto = parseFloat(this.datosLiquidacion.total_neto.toFixed(2))
          this.calcularTotalDif();
          this.calcularTotalBB();
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false;
    },
    calcularTotalDif(){ //calculo el campo total diferencia del panel 1
      this.detallesLiq.forEach(elem => {
        this.datosLiquidacion.total_diferencia += elem.diferencia
      })
      let aux = this.datosLiquidacion.total_diferencia;
      this.datosLiquidacion.total_diferencia = parseFloat(aux.toFixed(2));
    },
    calcularTotalBB(){ //calculo el campo TOTAL del panel 1
      let acu = 0;
      this.detallesLiq.forEach(elem => {
        acu += elem.importe_bb
      })
      this.datosLiquidacion.total_bb = parseFloat(acu.toFixed(2));
    },
    async guardar(){ //del primer panel
      if(this.datosLiquidacion.fecha_pago == '' || this.datosLiquidacion.fecha_pago == null || this.datosLiquidacion.fecha_emision == '' || this.datosLiquidacion.fecha_emision == null){
        this.$swal.fire({
          icon: 'error',
          title: `Error al guardar cambios`,
          text: 'Las fechas de Pago y de Emisión no puede estar vacías.'
        })
      }else{
        this.$store.state.loading = true
        await this.$store.dispatch('conciliacionManual/actualizarEncabezadoLiq',{
          liquidacion_id: this.filtros.liqId,
          fecha_emision: this.datosLiquidacion.fecha_emision,
          fecha_pago: this.datosLiquidacion.fecha_pago
        })
          .then((res) => {
            this.$swal.fire({icon: 'success', title: 'Existoso', text: 'Se guardaron los datos exitosamente en el Sistema',})
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          })
        this.$store.state.loading = false
      }
    },
    generarMovBancario(){ //boton amarillo primer panel
      this.$swal.fire({
        icon: 'warning',
        title: `Está por generar el Pago para la Liquidación ${this.filtros.liqId}. ¿Confirma esta accion?`,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
      }).then((res) => {
        if(res.isConfirmed){
          this.$swal.fire({
            icon: 'info',
            title: 'Escoja el tipo de Movimiento a generar',
            showCloseButton: true,
            showCancelButton: false,
            showDenyButton: true,
            confirmButtonText: 'Múltiples Mov. Bancarios',
            denyButtonText: 'Un solo Movimiento Bancario',
          }).then(async (result) => {
            if(result.isConfirmed){ //prepara y abre el modal, boton verde "multiples"
              this.movBancLiqObj.liqId = this.filtros.liqId;
              this.movBancLiqObj.totalNeto = this.datosLiquidacion.total_neto;
              this.dialogMov = true;
            }
            if(result.isDenied){//boton rojo
              if(this.datosLiquidacion.mov_bancario == '' || this.datosLiquidacion.mov_bancario == null){ //si NO hay mov_bancario, entra
                this.$store.state.loading = true
                await this.$store.dispatch('conciliacionManual/generarUnMovBancario',{
                  liquidacion_id: this.filtros.liqId
                })
                  .then(() => {
                    this.$swal.fire({icon: 'success', title: 'Existoso', text: 'Se generó el movimiento exitosamente.',})
                  })
                  .catch(error => {
                    this.$store.dispatch('show_snackbar', {
                      text: error.message,
                      color: 'error'
                    })
                  })
                this.$store.state.loading = false
              }else{
                this.$swal.fire({
                  icon: 'error',
                  title: `Error al Generar el Pago de la Liquidacion`,
                  text: 'La liquidación ya cuenta con movimientos bancarios asociados. No se puede proceder',
                })
              }
            }
          })
        }
      })
    },
    conciliarLiquidación(){ //boton azul primer panel
      this.$swal.fire({
        icon: 'warning',
        title: `Esta conciliando la Liquidación ${this.filtros.liqId}. ¿Confirma esta accion?`,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
      }).then(async (res) => {
        if(res.isConfirmed){
          this.$store.state.loading = true
          await this.$store.dispatch('conciliacionManual/conciliarTodo',{
            liquidacion_id: this.filtros.liqId
          })
            .then(() => {
              this.$swal.fire({icon: 'success', title: 'Existoso', text: 'Conciliación exitosa. La paǵina se refrescará',})
              setTimeout(() => [location.reload()], 3000)
            })
            .catch(error => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error'
              })
            })
          this.$store.state.loading = false
        }
      })
    },
    async rowClick(item, row){ //Cuando se le da click a la row y expande
      this.nroTarjeta = item.tarjeta_numero;
      this.nroLote = item.lote;
      this.nroCupon = item.cupon;
      this.seleccion = true;
      this.ultimaDetLiq = item.id;
      
      let existe = this.detallesLiq.filter(element => element.id == item.id)
      let pos = -1
      if (existe.length > 0) pos = this.detallesLiq.indexOf(existe[0])
      if (pos == -1){
        this.$store.dispatch('show_snackbar', {
          text: 'No se pudo determinar la posición del elemento en el listado.',
          color: 'error',
        })
        return
      }

      let selectState = (row.isSelected) ? false : true
      row.select(selectState)
      if (!row.isSelected){ //entra la primera vez cuando se hace click en la row
        this.expanded = [];
        this.expanded.push(item)
      }else{ //entra cuando contrae la row
        this.expanded = [];
      }
      if (this.expanded.length > 0){
        this.datosPedido = [];
        this.limpiar(); //limpia los campos del filtro pedidos estimados
        this.estimados = [];
        // paso a obtener datos del detalle seleccionado
        this.$store.state.loading = true;
        await this.$store.dispatch('conciliacionManual/obtenerDatosDetalleLiq',{
          detalle_id: item.id,
          tipo_pedido: 0,
          numero_pedido: 0
        })
          .then((res) => {
            if(res.datos_pedido.length > 0){
              // obtuve datos de liq
              this.expanded[0].datosPedido = res.datos_pedido;          
              this.datosPedido = res.datos_pedido;
              this.datosCaja = res.datos_caja;
            }else{
              // estimaciones
              this.estimados = res.estimaciones;
              if(item.estado_id == 1){ 
                this.$vuetify.goTo('#pedidosRecomendados') 
              }
            }
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          })
        this.$store.state.loading = false;
        
      }
    },
    async desconciliarUna(item){ //icono de la x roja de cada row de detallesLiq
      this.$swal.fire({
        icon: 'warning',
        title: `Esta desconciliando el detalle seleccionado, ${item.tarjeta_numero ? 'tarjeta Nro. ' + item.tarjeta_numero : 'Detalle nro '+item.id}. ¿Confirma esta accion?`,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
      }).then(async (res) => {
        if(res.isConfirmed){
          if(item.estado_id != 1){//si está conciliado ok y con obs entra
            this.$store.state.loading = true
            await this.$store.dispatch('conciliacionManual/desconciliarLiquidacion',{
              liquidacion_id: this.filtros.liqId,
              detalle_id: item.id
            })
              .then(async (result) => {
                let existe = this.detallesLiq.filter(elem => elem.id == item.id);
                if(existe.length > 0){
                  let pos = this.detallesLiq.indexOf(existe[0]);
                  this.detallesLiq[pos].estado_id = 1;
                  this.detallesLiq[pos].estado_nombre = 'SIN CONCILIAR';
                  this.detallesLiq[pos].fecha_conciliacion = null;
                  this.detallesLiq[pos].conciliacion_manual = 0;
                  this.detallesLiq[pos].observacion = '';
                  this.detallesLiq[pos].usuario_conciliacion = null;
                  this.datosPedido = []; //al desconciliar vacía
                  await this.$swal.fire({icon: 'success', title: 'Existoso', text: 'Desconciliación exitosa.',})
                  await this.$store.dispatch('conciliacionManual/obtenerDatosDetalleLiq',{
                    detalle_id: item.id,
                    tipo_pedido: 0,
                    numero_pedido: 0
                  })
                  .then((res) => {
                    if(res.datos_pedido.length > 0){
                      // obtuve datos de liq
                      //this.expanded[0].datosPedido = res.datos_pedido;          
                      this.datosPedido = res.datos_pedido;
                      this.datosCaja = res.datos_caja;
                    }else{
                      // estimaciones
                      this.estimados = res.estimaciones;
                      if(item.estado_id == 1){ 
                        this.$vuetify.goTo('#pedidosRecomendados') 
                      }
                    }
                  })
                  .catch(error => {
                    this.$store.dispatch('show_snackbar', {
                      text: error.message,
                      color: 'error'
                    })
                  })
                }
              })
              .catch(error => {
                this.$store.dispatch('show_snackbar', {
                  text: error.message,
                  color: 'error'
                })
              })
            this.$store.state.loading = false
          }else{
            this.$swal.fire({
              icon: 'error',
              title: `No se logró desconciliar`
            })
          }
        }
      })
    },
    async conciliarOk(item){ //acción de conciliar, ícono check verde
      if(cadenaVacia(item.observacion)){
        this.$swal.fire({
          icon: 'error',
          title: `La observación se encuentra vacía`,
          text: 'Se requiere que se ingrese una Observación'
        })
      }else{
        this.$swal.fire({
          icon: 'warning',
          title: `Esta marcando este detalle como Conciliado OK ¿Confirma esta accion?`,
          showCloseButton: true,
          showCancelButton: true,
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar',
        }).then(async (res) => {
          if(res.isConfirmed){
            this.$store.state.loading = true
            await this.$store.dispatch('conciliacionManual/conciliarOkDetalleLiq',{
              detalle_id: item.id,
              observacion: item.observacion
            })
              .then((res) => {
                let existe = this.detallesLiq.filter(elem => elem.id == item.id);
                if(existe.length > 0){
                  let pos = this.detallesLiq.indexOf(existe[0]);
                  this.detallesLiq[pos].estado_id = 2;
                  this.detallesLiq[pos].estado_nombre = 'CONCILIADO OK';
                  this.detallesLiq[pos].fecha_conciliacion = moment(new Date()).format("DD/MM/YYYY");
                  this.detallesLiq[pos].conciliacion_manual = 1;
                }
                this.$swal.fire({icon: 'success', title: 'Existoso', text: 'Conciliacion OK exitosa.',})
              })
              .catch(error => {
                this.$store.dispatch('show_snackbar', {
                  text: error.message,
                  color: 'error'
                })
              })
            this.$store.state.loading = false;
          }
        })
      }

    },
    async conciliarConObs(item){ //acción de conciliar Obs, ícono check azul
      if(cadenaVacia(item.observacion)){
        this.$swal.fire({
          icon: 'error',
          title: `La observación se encuentra vacía`,
          text: 'Se requiere que se ingrese una Observación'
        })
      }else{
        this.$swal.fire({
          icon: 'warning',
          title: `Esta marcando este detalle como Conciliado con Observaciones ¿Confirma esta accion?`,
          showCloseButton: true,
          showCancelButton: true,
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar',
        }).then(async (res) => {
          if(res.isConfirmed){
            this.$store.state.loading = true
            await this.$store.dispatch('conciliacionManual/conciliarConObsDetLiq',{
              detalle_id: item.id,
              observacion: item.observacion
            })
              .then(() => {
                let existe = this.detallesLiq.filter(elem => elem.id == item.id);
                if(existe.length > 0){
                  let pos = this.detallesLiq.indexOf(existe[0]);
                  this.detallesLiq[pos].estado_id = 5;
                  this.detallesLiq[pos].estado_nombre = 'CONCILIADO CON/OBS';
                  this.detallesLiq[pos].fecha_conciliacion = moment(new Date()).format("DD/MM/YYYY");
                  this.detallesLiq[pos].conciliacion_manual = 1;
                }
                this.$swal.fire({icon: 'success', title: 'Existoso', text: 'Conciliacion exitosa.',})
              })
              .catch(error => {
                this.$store.dispatch('show_snackbar', {
                  text: error.message,
                  color: 'error'
                })
              })
            this.$store.state.loading = false
          }
        })
      }


    },
    async verDatosCaja(item){ //ojito de color naranja
      if(this.datosCaja.length == 0){
        if(this.conManual){
          this.$store.state.loading = true;
          await this.$store.dispatch('conciliacionManual/obtenerDatosDetalleLiq',{
            detalle_id: this.ultimaDetLiq,
            tipo_pedido: item.tipo_pedido,
            numero_pedido: item.numero_pedido
          })
            .then((res) => {       
              this.datosCaja = res.datos_caja;
            })
            .catch(error => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error'
              })
            })
          this.$store.state.loading = false;
          this.itemObj = {
            item: item,
            datos_caja: this.datosCaja[0] ? this.datosCaja[0] : {} //si no hay nada objeto vacío
          }
          this.dialogDP = true;
          this.conManual = false;
        }else{
          this.$swal.fire({
            icon: 'error',
            title: `Sin Datos de Caja`,
            text: 'Ocurrio un problema al obtener los datos de Caja para este pedido'
          })
        }
      }else{
        //abrir modalDatosPedido
        this.$store.state.loading = true;
        await this.$store.dispatch('conciliacionManual/obtenerDatosDetalleLiq',{
          detalle_id: this.ultimaDetLiq,
          tipo_pedido: item.tipo_pedido,
          numero_pedido: item.numero_pedido
        })
          .then((res) => {       
            this.datosCaja = res.datos_caja;
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          })
        this.$store.state.loading = false;
        this.itemObj = {
          item: item,
          datos_caja: this.datosCaja[0] ? this.datosCaja[0] : {} //si no hay nada objeto vacío
        }
        this.dialogDP = true;
      }
    },
    async guardarObs(item){
      if(!item.observacion){
        this.$swal.fire({
          icon: 'error',
          title: `Observación vacía`,
          text: 'La observación no se puede guardar vacía.',
        })
        return
      }
      this.$swal.fire({
        icon: 'warning',
        title: `Estás por guardar una nueva observación \n ¿Confirma esta acción?`,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
      }).then(async (res) => {
        if(res.isConfirmed){
          this.$store.state.loading = true
          await this.$store.dispatch('conciliacionManual/guardarObservacionDetalleLiq',{
            detalle_id: item.id,
            observacion: item.observacion
          })
            .then((res) => {
              if(res.exito){
                this.$swal.fire({icon: 'success', title: 'Existoso', text: 'Se guardó correctamente la observación.',timer: 3000})
              }
            })
            .catch(error => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error'
              })
            })
          this.$store.state.loading = false

        }
      })

    },
    async buscar(){ //panel pedidos estimados
      if(!this.filtrosPedidosEstimados.numPedido && !this.filtrosPedidosEstimados.tipoPedido && !this.filtrosPedidosEstimados.formaPago &&
         !this.filtrosPedidosEstimados.codAutor && !this.filtrosPedidosEstimados.nroTar && !this.filtrosPedidosEstimados.marcaNro &&
         !this.filtrosPedidosEstimados.lote && !this.filtrosPedidosEstimados.cupon && !this.filtrosPedidosEstimados.cjaNro && 
         !this.filtrosPedidosEstimados.fechaDesde && !this.filtrosPedidosEstimados.fechaHasta && !this.filtrosPedidosEstimados.importe
      ){
        return this.$store.dispatch('show_snackbar', {
          text: 'Seleccione algún campo para filtrar',
          color: 'orange'
        })
      }
      if(this.filtrosPedidosEstimados.marcaNro){ //si tiene algo
        this.filtrosPedidosEstimados.marcaNom = (this.tarjetas.filter(elem => elem.tarjeta_codigo == this.filtrosPedidosEstimados.marcaNro))[0].tarjeta_nombre
      }
      
      this.load = true;
      this.estimados = [];
      await this.$store.dispatch('conciliacionManual/getPedidosEstimados', this.filtrosPedidosEstimados)
        .then((res) => {
          this.estimados = res.estimados;
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.load = false;
    },
    limpiar(){ //panel de pedidos estimados
      this.filtrosPedidosEstimados={
        numPedido: null,
        tipoPedido: null,
        formaPago: null,
        codAutor: null,
        nroTar: null,
        marcaNro: null,
        marcaNom: null,
        lote: null,
        cupon: null,
        cjaNro: null,
        fechaDesde: null,
        fechaHasta: null,
        importe: null,
        id: this.filtrosPedidosEstimados.id
      };
      this.search_2 = '';
      this.estimados = [];

    },
    async open_DatosdeCaja(item){ //ojo azul data-table
      this.$store.state.loading = true;
      await this.$store.dispatch('conciliacionManual/obtenerDatosDetalleLiq',{
        detalle_id: 0,//this.ultimaDetLiq,
        tipo_pedido: item.tipo_pedido,
        numero_pedido: item.numero_pedido
      })
        .then((res) => {       
          this.datosCaja = res.datos_caja;
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false;
      this.itemObj = {
        item: item,
        datos_caja: this.datosCaja[0] ? this.datosCaja[0] : {}
      }
      this.dialogDP = true;
    },
    async conciliarManualOp(item){ //conciliar Manual, check verde relleno del data-table
      this.$swal.fire({
        icon: 'warning',
        title: `Está por conciliar de forma manual. ¿Confirma esta accion?`,
        text: `El detalle ${this.ultimaDetLiq}, Nro de pedido: ${item.numero_pedido} y el Tipo de Pedido: ${item.tipo_pedido_nombre}`,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if(result.isConfirmed){
          await this.$store.dispatch('conciliacionManual/conciliarManualDetLiquidacion',{
            detalle_id: this.ultimaDetLiq,
            tabla: item.tabla, //valor "tabla"
            id: item.id_tabla, //valor "id_tabla"
          })
          .then((res) => {
            if(res.exito == 1){
              let existe = this.detallesLiq.filter(elem => elem.id == this.ultimaDetLiq);
              if(existe.length > 0){
                let pos = this.detallesLiq.indexOf(existe[0]);
                this.detallesLiq[pos].estado_id = res.estado;
                this.detallesLiq[pos].estado_nombre = 'CONCILIADO CON/OBS';
                this.detallesLiq[pos].fecha_conciliacion = moment(new Date()).format("DD/MM/YYYY");
                this.detallesLiq[pos].conciliacion_manual = 1;
                this.datosPedido = [];
                this.datosPedido.push(item);
                this.conManual = true;
                this.$vuetify.goTo('#SeccionDetalles')
              }
              this.estimados = [];
              this.limpiar();
              this.$swal.fire({icon: 'success', title: 'Existoso', text: 'Se guardaron los cambios en el Sistema',})
            }else{
              return this.$store.dispatch('show_snackbar', {text: res.message, color: 'error' })
            }
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          })
          this.$store.state.loading = false;
        }
      })

    },
    //panel descuentos
    open_modalAgregarDescuentos(){ //prepara y abre el modal
      this.descuentosObj.descuentos = this.descuentos;
      this.editarDesc = false;
      this.descuentosObj.editar = this.editarDesc;
      this.dialogDesc = true;
    },
    async agregarNvoDescuento(nvo){ //agregar el nvo al array de descuentosLiq que viene del modal
      await new Promise(resolve => setTimeout(resolve, 1))
      const index = this.descuentosLiq.map(elem => elem.descuento_id).indexOf(nvo.codigo)
      if(index == -1)
        this.descuentosLiq.push({
          "liquidacion_id": this.liqId,
          "descuento_id": nvo.codigo,
          "descuento_nombre": nvo.nombre,
          "tipo_descuento_id": nvo.tipoDescuentoCodigo,
          "tipo_descuento_nombre": nvo.tipoDescuento,
          "importe": nvo.importe,
          "descuento_enc_id": 0,
        })
      else{
        this.$swal.fire({
          icon: 'info',
          title: `Descuento ya Existente`,
        })
      }
      this.dialogDesc = false;
    },
    elim_descuento(item){ //elimina un descuento de la array descuentosLiq
      this.$swal.fire({
        icon: 'question',
        title: `Esta quitando este detalle de los Descuentos. ¿Confirma esta accion?`,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
      }).then((res) => {
        if(res.isConfirmed){
          this.descuentosLiq = this.descuentosLiq.filter(elem => elem.descuento_id != item.descuento_id);
        }
      })
    },
    editarDescuento(nvo){ //edita un descuento específico
      if(nvo.importe >= 0){
        const index = this.descuentosLiq.map(elem => elem.descuento_id).indexOf(nvo.codigo)
        this.descuentosLiq[index].importe = nvo.importe;
        this.dialogDesc = false;
      }else{
        this.$swal.fire({
          icon: 'error',
          title: `Importe incorrecto`,
          text: 'Por favor, ingrese valores numéricos únicamente',
        })
      }
    },
    editar_descuento(item){ //prepara todo para la apertura del modal
      this.descuentosObj.descuentos = this.descuentos;
      this.descuentosObj.item = item;
      this.editarDesc = true
      this.descuentosObj.editar = this.editarDesc;
      this.dialogDesc = true;
    },
    async guardarDescuentos(){ //guarda los cambios del panel de descuentos
      this.$swal.fire({
        icon: 'warning',
        title: `Esta guardando los Descuentos en el Sistema. ¿Confirma esta accion?`,
        showCloseButton: true,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Aceptar',
      }).then(async (res) => {
        if(res.isConfirmed){
          this.$store.state.loading = true
          await this.$store.dispatch('conciliacionManual/enviarDescuentosLiquidacion',{
            descuentos: this.descuentosLiq,
            liquidacion_id: this.filtros.liqId,
            total_neto: this.datosLiquidacion.total_neto
          })
            .then(() => {
              this.$swal.fire({icon: 'success', title: 'Existoso', text: 'Se guardaron los Descuentos en el Sistema',})
            })
            .catch(error => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error'
              })
            })
          this.$store.state.loading = false
        }
      })
    },
  },
  computed:{
    retornaAcuDesc(){ //panel descuentos
      let acu = 0;
      this.descuentosLiq.forEach(elem => acu += elem.importe)
      this.datosLiquidacion.descuentos = parseFloat(acu.toFixed(2));
      this.datosLiquidacion.total_neto = this.datosLiquidacion.total_bruto - this.datosLiquidacion.descuentos;
      this.datosLiquidacion.total_neto = parseFloat(this.datosLiquidacion.total_neto.toFixed(2));

      return parseFloat(acu.toFixed(2));
    },
    retornaAcuImpLiq(){ //panel detalles
      let acu = 0;
      this.detallesLiq.forEach(elem => acu += elem.importe_liq);
      return parseFloat(acu.toFixed(2));
    },
    retornaAcuDif(){//panel detalles
      let acu = 0;
      this.detallesLiq.forEach(elem => acu += elem.diferencia);
      return parseFloat(acu.toFixed(2));
    },
    retornaImporteAcu(){//panel detalles 
      let acu = 0;
      this.detallesLiq.forEach(elem => acu += elem.importe_bb);
      return parseFloat(acu.toFixed(2));
    },
  },
  components:{
    TextFieldMoneyVue, BtnConfirmarVue, ModalMovBancLiqVue, SearchDataTableVue, BtnFiltroVue,
    FechaPickerVue, ModalAgregarDescuentoVue, ModalDatosPedidoVue
  },
  watch:{
    '$vuetify.breakpoint.xs'(val){
      this.pantallaChica = val;
    },
  }
}
</script>

<style>

</style>